// src/App.tsx
import React, {useEffect, useState} from 'react';
import { BrowserRouter as Router, Route, Routes, Link, Navigate } from 'react-router-dom';
import Login from './screens/Login';

import Register from './screens/Register';
import Home from './screens/Home';
import More from './screens/More';
import Buy from './screens/Buy';
import Cronologia from './screens/cronology';
import Tos from './screens/Tos';
import { auth } from './FirebaseConfig';
import { onAuthStateChanged, getAuth } from 'firebase/auth';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import VerifyEmail from "./screens/VerifyEmail";
const stripePromise = loadStripe('pk_live_51Q8kOs2NSuCOV6T6PyHvS7AR3lyIYWqamkIQIE101x2WOZc1rX2VfiFxGj59GFzKwK3O3zGTytpOtxOMSTVAMzYn00nUQ1ft7z');


const App: React.FC = () => {
  const [logged, setlogged] = useState(false);
  const [completed, setcompleted] = useState(false);
   const auth = getAuth();
  const user = auth.currentUser;
  
  useEffect(() => {
    
    // Verifica che Firebase Authentication sia attivo e monitori lo stato di autenticazione
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setcompleted(false)
      setlogged(false)
      if (user) {
        console.log('Utente loggato:', user);
   
        setlogged(true)
        setcompleted(true)
      } else {
        console.log('Nessun utente loggato');
        setcompleted(true)
        setlogged(false)
      }
    });

    return () => unsubscribe(); // Cleanup per evitare memory leak
  }, [user?.emailVerified]);

  if (completed == false) {
    return null
  }



    return  (
      <Router>
        <Routes>
          {user ? (
            user.emailVerified ? (
              <>
                {/* Utente autenticato e verificato */}
                <Route path="/" element={<Home />} />
                <Route
                  path="/articles/:articleId"
                  element={
                    <Elements stripe={stripePromise}>
                      <More />
                    </Elements>
                  }
                />
                <Route path="/buy" element={<Buy />} />
                <Route path="/cronology" element={<Cronologia />} />
                <Route path="/tos" element={<Tos />} />
                <Route path="/login" element={<Navigate to="/" />} />
                <Route path="/register" element={<Navigate to="/" />} />
                <Route path="/verify-email" element={<Navigate to="/" />} />
              </>
            ) : (
              <>
                {/* Utente autenticato ma email non verificata */}
                <Route path="/" element={<Navigate to="/verify-email" />} />
                <Route path="/verify-email" element={<VerifyEmail />} />
                <Route path="/login" element={<Navigate to="/verify-email" />} />
                <Route path="/register" element={<Navigate to="/verify-email" />} />
              </>
            )
          ) : (
            <>
              {/* Utente non autenticato */}
              <Route path="/" element={<Navigate to="/login" />} />
              <Route path="/Buy" element={<Navigate to="/login" />} />
              <Route path="/Cronology" element={<Navigate to="/login" />} />
              <Route path="/More" element={<Navigate to="/login" />} />
              <Route path="/login" element={<Login />} />
              <Route path="/register" element={<Register />} />
              <Route path="/tos" element={<Tos />} />
            </>
          )}
        </Routes>
      </Router>
    );
};

export default App;
