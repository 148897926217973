import React, {useEffect, useState} from 'react';
import {Firestore, collection, doc, getDocs, getFirestore, onSnapshot, getDoc} from 'firebase/firestore'

import '../styles.css';
import { firestore } from '../FirebaseConfig'; 
import '../App.css';
import { useNavigate } from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { auth } from '../FirebaseConfig';
import { getStorage, ref, getDownloadURL, listAll } from "firebase/storage";
import { storage } from "../FirebaseConfig";
import blender from '../images/blender.jpg'; // Importa l'immagine
import Product from '../objects/Model';
import options from './altristili';
import { getIdToken, onAuthStateChanged, signOut } from 'firebase/auth';
import { useLocation } from "react-router-dom";
import { useStripe, useElements, CardElement ,CardNumberElement, CardExpiryElement, CardCvcElement } from '@stripe/react-stripe-js';
import { PayPalScriptProvider, PayPalButtons } from '@paypal/react-paypal-js';
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';
import { getCode } from "country-list";
import axios from 'axios';
import Immagine from '../objects/Immagine';
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
interface Model {
  immagine: string;
}


interface PaymentStatus {
  status: 'IDLE' | 'PENDING' | 'COMPLETED' | 'ERROR';
}

interface ModelD {
  id: string,
  nome: string,
  immagine: string,
  descrizione: string
  prezzo: number,
  categoria: string
  storagePath: string,
}

const More: React.FC = () => {
  const stripe = useStripe();
  const elements = useElements();
  const [isProcessing, setIsProcessing] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingmodels, setLoadingmodels] = useState(false);
  const [loadingpage, setLoadingpage] = useState(false);
  const [error, setError] = useState(false);
  const [carta, setcarta] = useState(true);
  const [erroremessaggio, seterroremessaggio] = useState('');
  const [country, setCountry] = useState('');
  const { articleId } = useParams();
  const [model, setModel] = useState<ModelD>(
    {
      id: '',
      nome: 'Nome non disponibile',
      descrizione: 'Descrizione non disponibile',
      immagine: '',
      prezzo: 0,
      categoria: 'Categoria non disponibile',
      storagePath: 'Path non disponibile',
    }
  ); // Stato per il modello
  useEffect(() => {
    const fetchModel = async () => {
        try {
            // Riferimento al documento specifico
            if (articleId) {
            const docRef = doc(firestore, "Modelli", articleId);
            const docSnap = await getDoc(docRef);
            
            if (docSnap.exists()) {
                // Ottieni il riferimento all'immagine nel bucket
                const imageRef = ref(storage, `Modelli/${articleId}/${articleId}.jpg`);
                const imageUrl = await getDownloadURL(imageRef);

                // Costruisci l'oggetto modello con gli attributi
                const modelData = {
                    id: docSnap.id,
                    nome: docSnap.data()?.Nome || "Nome non disponibile",
                    descrizione: docSnap.data()?.Descrizione || "Descrizione non disponibile",
                    immagine: imageUrl,
                    prezzo: docSnap.data()?.Prezzo || "Prezzo non disponibile",
                    categoria: docSnap.data()?.Categoria || "Categoria non disponibile",
                    storagePath: docSnap.data()?.Storagepath || "Path non disponibile",
                };

                setModel(modelData);
                setLoadingmodels(true) // Aggiorna lo stato con i dati del modello
            } else {
                console.error("Documento non trovato!");
            }
          } else{
            return null;
          }
        } catch (error) {
            console.error("Errore nel recupero del documento:", error);
        }
    };

    fetchModel(); // Chiama la funzione di fetch
}, []);



const NOME = model?.nome;
const DESC = model?.descrizione;
const id = model?.id;
const path = model?.storagePath;
const PREZZO = model?.prezzo;
const IMG  = model?.immagine;




const formatEuro = (value: number, value2: number) => {
  return new Intl.NumberFormat("it-IT", {
    style: "currency",
    currency: "EUR",
  }).format(value + value2);
};





  const taxes = () => {
    let tassa = 0
    if (carta) {
      
      tassa = Math.round(PREZZO *0.029 + 0.35 * Math.pow(10, 2)) / Math.pow(10, 2);
    }else{
     tassa =  Math.round(PREZZO *0.0349 + 0.35 * Math.pow(10, 2)) / Math.pow(10, 2);
     
      
    }
    return tassa
  }
  const [paymentStatus, setPaymentStatus] = React.useState<PaymentStatus['status']>('IDLE');
  const [haspayed, sethaspayed] = useState(false);
 
  const initialOptions = {
    clientId:
        "ASVNRhZxbffZFUlInn_oN099M2MnfU-6zkD8GzGd83Z9IKUwyhzaFqXL5FEZnkVcya_Q6PdDgCJP0QeT",
    currency: "EUR",
};

  const [inputValue, setInputValue] = useState({
    name: "",
    Country: "",
    City: "",
    Zip: "",
    Address: "",
  });


  const downloadFile = (url: string) => {

    const link = document.createElement('a'); // Crea un elemento <a>
    link.href = url; // Imposta l'URL del file
    link.download = ''; // Imposta l'attributo download
    document.body.appendChild(link); // Aggiungi il link al DOM
    link.click(); // Simula un clic sul link per avviare il download
    document.body.removeChild(link); // Rimuovi il link dal DOM
  };

  const handlePayment = async (event: React.FormEvent) => {
    event.preventDefault();
    if (isProcessing) return; // Prevenzione di richieste multiple
    setIsProcessing(true);
    if (!stripe || !elements) {
      return; // Stripe.js is not loaded properly
    }

    const cardNumberElement = elements.getElement(CardNumberElement);
 

  if(!cardNumberElement ) {
  
    return 
  } 



  setLoading(true);
   if (inputValue.name.length == 0 || inputValue.Address.length == 0 || inputValue.City.length == 0 || inputValue.Country === " " || inputValue.Zip.length == 0 ) {
    setLoading(false);
    setError(true)
    seterroremessaggio("Please fill in all the fields.");
    return;
   }
 
    const billingDetails = {
      name: inputValue.name,  // Sostituisci con il nome dell'utente
      address: {
        city: inputValue.City,          // Sostituisci con la città dell'utente
        country:  getCode(country),          // Codice del paese a 2 lettere (es. 'IT' per l'Italia)
        line1: inputValue.Address,   // Indirizzo dell'utente
        postal_code: inputValue.Zip,     // Codice postale dell'utente
      }
    };

    const { error: paymentError, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardNumberElement, // Numero della carta
      billing_details: billingDetails,
    });

    if (paymentError) {
      console.error('[Errore di pagamento]', paymentError);
      setLoading(false);
     setError(true)
     seterroremessaggio("Error while creating the payment method, please try again.");
      return;
    }

    
try {

  const user = auth.currentUser;
  if (!user) {
    return null; // Nessun token disponibile
}
  const token = await user.getIdToken();
  const response = await axios.post('https://processpayment-4akmtbnxjq-uc.a.run.app/processPayment', {
    modello: id,
    paymentMethodId: paymentMethod.id, // ID del metodo di pagamento
    token: token,
    path: path,
  });

  if (response.data.success) {
    const fileUrl = response.data.fileUrl; // Ottieni l'URL del file dal server
    console.log("File URL:", fileUrl);
    
    // Opzionale: reindirizza l'utente all'URL del file per il download
   downloadFile(fileUrl);
   sethaspayed(true);
  } else if (response.data.requiresAction) {
    const result = await stripe.confirmCardPayment(response.data.clientSecret);
    if (result.error) {
      // Gestisci l'errore di autenticazione
      setError(true);
      seterroremessaggio("The payment was unsuccessful, please try again.");
    } else if (result.paymentIntent && result.paymentIntent.status === "succeeded") {
      // Il pagamento è stato completato con successo
      sethaspayed(true);
      console.log("Pagamento completato!");
    }
  }

} catch (error) {
  seterroremessaggio("The payment was unsuccessful, please try again.");
} finally {
  setLoading(false);
  setIsProcessing(false);
}
  }
  
  const handledownload = async () => {

    try {

      const user = auth.currentUser;
      if (!user) {
        console.error("Nessun utente autenticato. Assicurati di aver effettuato il login.");
        return null; // Nessun token disponibile
    }
      const token = await user.getIdToken();
      setLoading(true);
      const response = await axios.post('https://getfile-4akmtbnxjq-uc.a.run.app/getfile', {
        modello: id,
        token: token,
        path: path
      });
    
      if (response.data.success) {
        const fileUrl = response.data.fileUrl; // Ottieni l'URL del file dal server
        console.log("File URL:", fileUrl);
        
        // Opzionale: reindirizza l'utente all'URL del file per il download
       downloadFile(fileUrl);
      } else {
        // Gestisci l'errore di pagamento se il server restituisce un errore
    console.log("gatwadawdaw")
      }
    
    } catch (error) {
      console.error("Errore durante la richiesta:", error);
     
    } finally {
      setLoading(false);
    }
  }

  
  const [models, setModels] = useState<Model[]>([]);




  const handleinputchange = (event: React.ChangeEvent<HTMLInputElement>)  => {
    const {name, value} = event.target;
  console.log(value)
    setInputValue({
      ...inputValue,
      [name]: value
    });
  }



//getmodels
useEffect(() => {
  const fetchImages = async () => {
    try {
      const imagesFolderRef = ref(storage, `Modelli/${id}/More`);

      // Ottieni i riferimenti di tutte le immagini nella cartella
      const imageRefs = await listAll(imagesFolderRef);

      // Mappa su ogni riferimento per ottenere l'URL di download
      const imageUrls = await Promise.all(
        imageRefs.items.map(async (imageRef) => {
          const imageUrl = await getDownloadURL(imageRef);
          return { immagine: imageUrl }; // Creiamo un oggetto Model
        })
      );

      setModels(imageUrls); // Aggiorna lo stato con l'array di URL delle immagini
    } catch (error) {
      console.error("Errore nel recuperare le immagini:", error);
    }
  };

  fetchImages();
}, [id]); // Si aggiorna solo una volta all'inizio

  useEffect(() => {
    const fetchData = async () => {
      const userName = auth.currentUser?.uid; // Ottieni il nome utente corrente
      if (!userName) {
        console.error("Nessun utente autenticato.");
        return;
      }
      try {
        // Accedi al documento della collezione "Modelli"
        console.log(userName, path)
        const docRef = doc(collection(firestore, "utenti"), userName, "ordiniubuy", path);
        
        // Usa getDoc per recuperare il documento
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          sethaspayed(true);
        } else {
          console.log("Nessun documento trovato!");
        }
      } catch (error) {
        console.error("Errore nel recupero del documento:", error);
      } finally {
        if(loadingmodels) {setLoadingpage(true);}
        
      }
    };

    fetchData(); // Chiama la funzione fetchData
  }, [path]); // Aggiungi le dipendenze se necessario





 const cliccatoelemento= (element: boolean) => {

setcarta(element);

 }
 const logout =  async ()  => {

  signOut(auth);
  }



if (!loadingmodels || !loadingpage) {
  return <div className='h-screen w-screen bg-home2 flex justify-center items-center'>
  <div className="loader2"></div>
  </div>
}



  return (
    <div className='flex flex-col h-screen  overflow-auto bg-home2  scrollbar-custom '  >
    <header className="flex     text-[1.3rem]  text-[#ECC5A0] font-imperial p-1">
      <div className='w-[30%] hidden sm:flex'>
      Gianluigi Izzo  
      </div>
      <div className='flex flex-1 items-center justify-center   text-[#8b8b8b] text-[0.7rem] space-x-3  '>
      <Link className='flex   justify-center     font-inter ' to="/">
       
       Home
          
     
            
           
           </Link>
       
           <Link className='flex     justify-center     font-inter ' to="/Buy">
            
            Buy Models
               
          
                 
                
                </Link>
                <Link className='flex    justify-center     font-inter ' to="/Cronology">
            
            Cronology boughts
               
          
                 
                
                </Link>
                <Link className='flex   justify-center     font-inter ' to="/TOS">
            
            Terms of Service
               
          
                 
                
                </Link>
           </div>
           <div className='flex w-[30%] font-inter text-[0.8rem] items-center justify-end'>
    
    {auth.currentUser?.displayName}
    <div className='flex  font-inter '>
  <button onClick={logout} className='bg-[#A47040] ml-2 text-[0.7rem] py-1 text-white px-1 rounded-[5px] '>
    Log out
    </button>
</div>
</div>
    </header>
  
   
     
  
        {/* Parte iniziale */}
        {haspayed?
<div className='flex flex-col items-center pt-5 flex-1 space-y-[0.2rem]'>
<p className='text-white text-[2.6rem] font-semibold  '>Thank you</p>
<p className='text-[#c4c4c4] text-[0.7rem] w-[24rem] text-center' >Thank you for your purchase! We’re thrilled you chose our product and are confident it will meet your expectations. Your support means a lot to us, and we look forward to serving you again soon!</p>
<div className='flex w-full flex-row py-4 justify-center space-x-4'>
<button onClick={handledownload} className=' h-[1.6rem]  px-6 bg-[#3ebe24] text-white rounded-[5px]  text-[0.7rem]'>Download</button>
<button onClick={handledownload} className=' h-[1.6rem]  px-6 bg-[#d48d3c] text-white rounded-[5px]  text-[0.7rem]'>Back to home</button>
</div>
</div>
        :
       <div className='flex flex-row justify-center  '>
<div className='lg:flex flex-col  hidden'>
  
<div className='flex w-[7rem] h-[13rem] overflow-hidden bg-slate-100 m-[1rem]'>
<img
        src={IMG} // sostituisci con il tuo URL dell'immagine
        alt="example"
        className="object-cover object-left "
      />

</div>
<div className='flex w-[7rem] h-[13rem] bg-slate-100 m-[1rem] mt-[2rem] '>
<img
        src={IMG} // sostituisci con il tuo URL dell'immagine
        alt="example"
        className="object-cover object-right "
      />
</div>


</div>
     <div className='flex-col'>





        <div className='flex justify-start flex-col md:flex-row    '>
      
        <div className='flex flex-col   items-center md:items-start      pt-5   '>
  
  <p className=' text-[2rem]  leading-[1.4rem] font-semibold    font-inter m-0 p-0 text-[#E4D2D2]'>
This is the
  </p>
 
  <p className='text-[2.6rem] leading-[3.7rem] font-semibold   font-inter     text-[#ECC5A0]'>
  {NOME}
  </p>
  <div className='flex flex-row items-center'>
  <p className='text-[0.6rem] pb-2 px-2 md:pb-0   md:pl-0   md:text-left  text-center font-inter  max-w-[22rem]    text-[#A39C96]'>
{DESC}  </p>


</div>
  <div className='sm:hidden flex flex-col pt-3 px-2'>
 <div className=' sm:hidden   inline-flex first-line space-x-2 pb-5  '>
 <div className='inline-flex first-line space-x-2 scrollbar-thin  overflow-x-auto '>

 {models.map((model) => (
  // Verifica se il prezzo del modello è maggiore di 30
  
 (
    <Immagine
      key={model.immagine} // Usare una chiave univoca per ogni elemento in lista
      immagine={model.immagine}
    />
  ) 
))}

</div>
 </div>
 </div>
  </div>

      
      
    
       </div>


       <div className='flex justify-center flex-row md:flex-row md:items-start  items-center   py-0  md:py-2 '>
      
      <div className='md:flex flex-col hidden '>
       <div className='flex w-[7rem] h-[13rem] bg-slate-100 mr-10   '>
       <img
        src={IMG} // sostituisci con il tuo URL dell'immagine
        alt="example"
        className="object-cover object-center "
      />
        </div>
       <p className=' text-[0.8rem]  leading-[1rem] font-semibold text-center mr-10 mt-4   font-inter m-0 p-0 text-[#E4D2D2]'>
Need more 
 
  </p>
  <p className=' text-[0.8rem]  leading-[1rem] font-semibold text-center mr-10    font-inter m-0 p-0 text-[#E4D2D2]'>
Informations?

  </p>  
  <button className='bg-[#A47040] rounded-[6px] text-[0.7rem] text-white mt-4  mr-10  py-[0.2rem]'>Contact me</button>
       </div>

  <div className='flex sm:flex-row flex-col border-2 pl-2  border-[#4A443F] rounded-[7.5px]  ' >
  
  {/* Parte 1 */}

  <div className='flex flex-col w-[18rem] p-2  '>
  <p className='text-[1rem] leading-[1.5rem]  font-semibold pb-0  font-inter     text-[#fcfcfc]'>
Payment
</p>
<p className='text-[1rem] leading-[1.5rem] font-semibold pb-0  font-inter     text-[#fcfcfc]'>
Method
</p>
<div className=' flex-row space-x-2 flex'>
<div className='flex flex-col'>
<button onClick={() => cliccatoelemento(true)}  className='text-[0.6rem]  pl-2 leading-[1.5rem] font-normal pb-0  font-inter     text-[#b9b9b9]'>
Credit card
</button>
<div style={{display: carta ? "flex" : "none"}} className=' mx-3 ml-6 border-[1px] border-[#4CEA4C]'>

</div>

</div>
<div className='flex flex-col'>
<button onClick={() => cliccatoelemento(false)}  className='text-[0.6rem] pl-2 leading-[1.5rem] font-normal pb-0  font-inter     text-[#b9b9b9]'>
Paypal
</button>
<div style={{display: carta ? "none" : "flex"}} className=' mx-1 ml-4 border-[1px] border-[#4CEA4C]'>

</div>

</div>
</div>
    {/* Carte di credito */}
    <div style={{display: carta ? "flex" : "none", flexDirection: carta ? "column" : "row" }}>
<div className='flex flex-row space-x-2 pt-2   '>
{/* Elenco */}



</div>
<div className='flex flex-col  '>

<p  style={{color: inputValue.name === "" && error ? "red": "#aaaaaa",}} className='text-[0.6rem] pt-2   font-normal   font-inter     '>
Card Holder
</p>


      <input
      type="text"
      style={{
        borderBottom: inputValue.name === "" && error   ? "2px solid red": "2px solid #6161617a", // Specifica spessore, stile e colore insieme
      }}
      className='bg-transparent border-b-2 border-[#6161617a] text-white placeholder-[#919191] border-collapse mr-[2rem]  py-1  text-[0.6rem] '
      value={inputValue.name} // Lega l'input allo stato
        name="name"
      onChange={handleinputchange} // Gestore per il cambiamento dell'input
      placeholder='Insert Card Holder'
    />
<div className='flex flex-1 flex-row space-x-6'>
<div className='flex flex-col'>
<p style={{color: country === "" && error ? "red": "#aaaaaa",}} className='text-[0.6rem] pt-2   font-normal   font-inter     text-[#aaaaaa]'>
Country
</p>
<CountryDropdown
      classes="custom-dropdown"
      id="country"
      value={country}
      onChange={(val) => setCountry(val)}
      
    />
      </div>
      <div className='flex flex-col '>
    <p style={{color: inputValue.City === "" && error ? "red": "#aaaaaa",}}  className='text-[0.6rem] pt-2   font-normal   font-inter     text-[#aaaaaa]'>
City
</p>
<input
      type="text"
      style={{
        borderBottom: inputValue.City === "" && error   ? "2px solid red": "2px solid #6161617a", // Specifica spessore, stile e colore insieme
      }}
      className='bg-transparent  text-white placeholder-[#6e6e6e] border-collapse   py-1  text-[0.6rem] '
      value={inputValue.City} // Lega l'input allo stato
        name="City"
      onChange={handleinputchange} // Gestore per il cambiamento dell'input
      placeholder='Insert your City'
    />
  </div>
    </div>
    <div className='flex flex-1 flex-row space-x-6'>
<div className='flex flex-col'>
<p style={{color: inputValue.Zip === "" && error ? "red": "#aaaaaa",}} className='text-[0.6rem] pt-2   font-normal   font-inter     '>
Zip code
</p>
<input
      type="text"
      style={{
        borderBottom: inputValue.Zip === "" && error   ? "2px solid red": "2px solid #6161617a", // Specifica spessore, stile e colore insieme
      }}
      className='bg-transparent  text-white placeholder-[#6e6e6e] border-collapse   py-1  text-[0.6rem] '
      value={inputValue.Zip} // Lega l'input allo stato
        name="Zip"
      onChange={handleinputchange} // Gestore per il cambiamento dell'input
      placeholder='Insert your Zip code'
    />
      </div>
      <div className='flex flex-col '>
    <p style={{color: inputValue.Address === "" && error ? "red": "#aaaaaa",}} className='text-[0.6rem] pt-2   font-normal   font-inter     '>
Address
</p>
<input
style={{
  borderBottom: inputValue.Address === "" && error   ? "2px solid red": "2px solid #6161617a", // Specifica spessore, stile e colore insieme
}}
      type="text"
      className='bg-transparent  text-white placeholder-[#6e6e6e] border-collapse   py-1  text-[0.6rem] '
      value={inputValue.Address} // Lega l'input allo stato
        name="Address"
      onChange={handleinputchange} // Gestore per il cambiamento dell'input
      placeholder='Insert your Address'
    />
  </div>
    </div>
    
</div>
<div className='flex flex-col pt-1 '>
<p  className='text-[0.6rem] pt-2   font-normal   font-inter   text-[#aaaaaa]  '>
Card Number
</p>
<CardNumberElement  className='border-b-2  mr-[2rem] border-[#6161617a] py-1' options={options}  />
</div>

<div className='flex flex-row pt-1 '>
<div className='flex flex-col  '>

<p className='text-[0.6rem] pt-2    font-normal   font-inter     text-[#aaaaaa]'>
Expiry Date
</p>


   <CardExpiryElement className='border-b-2  border-[#6161617a] py-1' options={options}/>
</div>
<div className='flex flex-1'>

</div>
<div className='flex flex-col mr-[2rem] '>

<p className='text-[0.6rem] pt-2   font-normal    font-inter     text-[#aaaaaa]'>
CVV
</p>


<CardCvcElement className='border-b-2 w-[3rem]  border-[#6161617a] py-1' options={options}/>
</div>


</div>

</div> 


{/* Paypal */}

<div className='flex-1 justify-center items-center align-middle pt-5' style={{display: carta ? "none" : "flex", flexDirection: carta ? "row" : "column" }}>

<div className='flex-1 w-[15rem]'>




<div className='flex flex-row'>
<p className='flex flex-1 text-[0.5rem]  text-start    pt-2  p-3 md:text-left   font-inter  max-w-[23rem]    text-[#777777]'>
Subtotal
</p>
<p className='flex text-[0.5rem]   justify-center    pt-2  p-3 md:text-left  text-center font-inter  max-w-[23rem]    text-[#777777]'>
{formatEuro(PREZZO, 0)}
</p>
</div>
<div className='flex flex-row'>
<p className='flex flex-1 text-[0.5rem]  text-start    pt-0  p-3 md:text-left   font-inter  max-w-[23rem]    text-[#777777]'>
Taxes
</p>
<p className='flex text-[0.5rem]   justify-center    pt-0  p-3 md:text-left  text-center font-inter  max-w-[23rem]    text-[#777777]'>
{formatEuro(taxes(), 0)}
</p>
</div>



<div className='flex pt-4 sm:pt-0  px-2 items-center '>
 <div className='flex flex-1 bg-[#6d6d6dc7] h-[1px] '></div>
   </div>

   <div className='flex flex-row'>
<p className='flex flex-1 text-[0.6rem]  text-start    pt-2  p-3 md:text-left   font-inter  max-w-[23rem]    text-[#777777]'>
Total
</p>
<p className='flex text-[0.6rem]   justify-center    pt-2  p-3 md:text-left  text-center font-inter  max-w-[23rem]    text-[#777777]'>
{formatEuro(PREZZO, taxes())}
</p>
</div>




    

</div>
<PayPalScriptProvider options={initialOptions}>
  <PayPalButtons
    fundingSource='paypal'
    className='w-[13rem] pt-3'
    style={{ layout: "vertical" }}
    createOrder={async () => {
   
    const user = auth.currentUser
    if (!user) {
      return null
    }
    const token = await user.getIdToken()

console.log(token)

      const order = await axios.post('https://createpaypalorder-4akmtbnxjq-uc.a.run.app/createpaypalorder', {
        modello: id,
        token: token, 
        path: path,
      });
      console.log(order)
      return order.data.orderId;
    }}
    onApprove={(data, actions) => {
      setPaymentStatus('PENDING');
      if (actions && actions.order) {
        return actions.order.capture().then((details) => {
          setPaymentStatus('COMPLETED');
          sethaspayed(true);
        });
      } else {
        console.error("actions.order è undefined");
        setPaymentStatus('ERROR');
        return Promise.reject(new Error("actions.order è undefined"));
      }
    }}
    onError={(err) => {
      console.error("Errore nel pagamento", err);
      setPaymentStatus('ERROR');
    }}
  />
</PayPalScriptProvider>
<p className='flex text-[0.4rem]  justify-center align-middle   pt-2  p-3   text-center font-inter  max-w-[23rem]    text-[#777777]'>
By clicking this button, the PayPal security page will pop up, allowing you to continue the payment process.
</p>
<div className=' flex justify-center'>
          {paymentStatus === 'PENDING' && <p className='text-[0.4rem] text-orange-300'>Pagamento in corso...</p>}
          {paymentStatus === 'COMPLETED' && <p className='text-[0.4rem] text-green-400' >Pagamento completato con successo!</p>}
          {paymentStatus === 'ERROR' && <p className='text-[0.4rem] text-red-400' >Errore durante il pagamento. Riprova.</p>}

          </div>
</div>

</div>
{/* Divisore */}



    <div className='flex border-[1px] border-[#4A443F]'>
    
    </div> 





{ carta?
<div className='flex flex-col  items-center '>
<img src={IMG} alt="Logo" className='w-[10.5rem] pt-4  px-6  brightness-[90%]  '/> {/* Inserisci l'immagine */}
<div className='flex-1'>
<p className='flex text-[0.6rem]  justify-center    pt-2  md:text-left  text-center font-inter  max-w-[23rem]    text-[#ffffff]'>
{NOME}
</p>

<p className='flex text-[0.4rem] sm:w-[11rem] w-[15rem] justify-center    pt-2  p-3 md:text-left  text-center font-inter  max-w-[23rem]    text-[#777777]'>
This is the Check-out page! Please fill out the required fields to complete your purchase. When you're ready, click 'Pay Now' to start the payment process.
</p>

<div className='flex pt-4 sm:pt-0  px-2 items-center '>
 <div className='flex flex-1 bg-[#6d6d6dc7] h-[1px] '></div>
   </div>

<div className='flex flex-row'>
<p className='flex flex-1 text-[0.5rem]  text-start    pt-2  p-3 md:text-left   font-inter  max-w-[23rem]    text-[#777777]'>
Subtotal
</p>
<p className='flex text-[0.5rem]   justify-center    pt-2  p-3 md:text-left  text-center font-inter  max-w-[23rem]    text-[#777777]'>
{formatEuro(PREZZO, 0)}
</p>
</div>
<div className='flex flex-row'>
<p className='flex flex-1 text-[0.5rem]  text-start    pt-0  p-3 md:text-left   font-inter  max-w-[23rem]    text-[#777777]'>
Taxes
</p>
<p className='flex text-[0.5rem]   justify-center    pt-0  p-3 md:text-left  text-center font-inter  max-w-[23rem]    text-[#777777]'>
{formatEuro(taxes(), 0)}
</p>
</div>



<div className='flex pt-4 sm:pt-0  px-2 items-center '>
 <div className='flex flex-1 bg-[#6d6d6dc7] h-[1px] '></div>
   </div>

   <div className='flex flex-row'>
<p className='flex flex-1 text-[0.6rem]  text-start    pt-2  p-3 md:text-left   font-inter  max-w-[23rem]    text-[#777777]'>
Total
</p>
<p className='flex text-[0.6rem]   justify-center    pt-2  p-3 md:text-left  text-center font-inter  max-w-[23rem]    text-[#777777]'>
{formatEuro(PREZZO, taxes())}
</p>
</div>




    

</div>
<div className='flex pb-2 justify-center items-end'>
  
{loading? 
  <div className='bg-[#213612] rounded-[6px] px-8 text-[0.7rem] text-white mt-4    py-[0.15rem]'>
 

  <div className="loader"></div>


  
  </div>

:
<div>
<div className='flex flex-col items-center '>
<button onClick={handlePayment} className='bg-[#467427] rounded-[6px] px-5 text-[0.7rem] text-white mt-4 mb-1   py-[0.15rem]'>Pay now</button>
{error?
<p className='text-[0.4rem] max-w-[10rem] text-[#ff3636]'>{erroremessaggio}</p>
:
<p className='text-[0.4rem] max-w-[10rem] text-[#777777]'>This is the checkout! After clicking the button we will automatically run the payment.</p>
}
</div>
</div>
}




</div>
</div>
:
<div></div>
}



  </div>  

      
    
  
     </div>
     </div>
     </div>



  }



    


<div className='flex pt-4  px-2 items-center '>
   <div className='flex flex-1 bg-[#6d6d6dc7] h-[1px] '>
   </div>
     </div>

     <p className='text-[2rem] hidden sm:flex leading-[3.7rem] font-semibold   font-inter    pl-0   sm:pl-[4rem] text-[#ffffff]'>
 Gallery of {NOME}
 </p>
 <div className=' hidden   sm:inline-flex first-line space-x-2 pl-14  '>
 <div className='inline-flex first-line space-x-2 scrollbar-thin  overflow-x-auto scrollbar-custom '>

 {models.map((model) => (
  // Verifica se il prezzo del modello è maggiore di 30
  
 (
    <Immagine
      key={model.immagine} // Usare una chiave univoca per ogni elemento in lista
      immagine={model.immagine}
    />
  ) 
))}
</div>
 </div>










 <div className='flex justify-center mt-2 text-white text-[0.6rem] '>
    © 2024  da Gianluigi Izzo
    </div>
    </div>
  );
}

export default More;


