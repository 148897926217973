import React, {useEffect, useState} from 'react';
import '../styles.css';
import logo from './logo.svg';
import '../App.css';
import { Link } from 'react-router-dom';
import Logo from "../images/Logo.png"
import { useNavigate } from 'react-router-dom';
import { auth } from '../FirebaseConfig';
import io from '../images/io6.svg'; // Importa l'immagine
import blender from '../images/blender.jpg'; // Importa l'immagine
import santa from '../images/Santa.png'; // Importa l'immagine
import { onAuthStateChanged, signOut } from 'firebase/auth';
const Home: React.FC = () => {
  const navigate = useNavigate();
  const [logged, setlogged] = useState(false);
  const goToAboutPage = () => {
    navigate('/buy');
  };

  useEffect(() => {
    // Verifica che Firebase Authentication sia attivo e monitori lo stato di autenticazione
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        console.log('Utente loggato:');
        setlogged(true)
   
      } else {
        console.log('Nessun utente loggato');
  
      }
    });

    return () => unsubscribe(); // Cleanup per evitare memory leak
  }, []);


const logout =  async ()  => {

signOut(auth);
}


  if (logged == false) {
    return (
      <div>request failed: not logged</div>
    )
   
    }

  return (
    <div className='flex flex-col h-screen  overflow-auto bg-home2  scrollbar-custom '  >
    <header className="flex     text-[1.3rem]  text-[#ECC5A0] font-imperial p-3 ">
      <div className='w-[30%] hidden sm:flex'>
      Gianluigi Izzo  
      </div>
      <div className='flex flex-1 items-center justify-center   text-[#8b8b8b] text-[0.7rem] space-x-3  '>
      <Link className='flex text-[#ffffff]   justify-center     font-inter ' to="/">
       
       Home
          
     
            
           
           </Link>
       
           <Link className='flex   justify-center     font-inter ' to="/Buy">
            
            Buy Models
               
          
                 
                
                </Link>
                <Link className='flex   justify-center     font-inter ' to="/Cronology">
            
            Cronology boughts
               
          
                 
                
                </Link>
                <Link className='flex   justify-center     font-inter ' to="/TOS">
            
            Terms of Service
               
          
                 
                
                </Link>
           </div>
    <div className='flex w-[30%] font-inter text-[0.8rem] items-center justify-end'>
    
        {auth.currentUser?.displayName}
        <div className='flex  font-inter '>
      <button onClick={logout} className='bg-[#A47040] ml-2 text-[0.7rem] py-1 text-white px-1 rounded-[5px] '>
        Log out
        </button>
    </div>
    </div>
    </header>
  
    {/* Scritta iniziale */}
    <div className='flex flex-col sm:flex-row items-center sm:items-start    justify-center  '>
    <div className='flex    mt-14   '>
      <div className='flex flex-col sm:items-start items-center  pt-6  flex-1   '>
  
 <p className=' text-[2rem]  leading-[1.4rem] font-semibold  pl-0   sm:pl-[4rem]  font-inter m-0 p-0 text-[#E4D2D2]'>
  I'm
 </p>

 <p className='text-[3rem] leading-[3.7rem] font-semibold   font-inter    pl-0   sm:pl-[4rem] text-[#ECC5A0]'>
  Gianluigi Izzo
 </p>
 <p className='text-[0.6rem] sm:pr-0  pr-10 pl-10  py-2  font-inter  max-w-[30rem] sm:text-left  text-center    sm:pl-[4rem] text-[#A39C96]'>
 “ Hello. My name is Gianluigi and I'm a professional delevoper in many aspects, from making models, script, UIs on roblox to make 3D models to blender, and again from blender to website and app developer with react.js and firebase “   
 </p>

 <div className='flex   sm:pl-[4rem] pt-5 space-x-3 '>
  <a href='mailto:regelly150@gmail.com?subject=%5BInserire%20oggetto%20della%20discussione%5D&body=%5BDimmi%20quello%20che%20vuoi%20sapere!%5D' className='bg-[#A47040] rounded-[6px] text-[0.7rem] text-center text-white w-[7rem] py-[0.4rem]' >Contact me</a>
  <button onClick={goToAboutPage} className='bg-transparent  rounded-[6px] text-[0.7rem] text-white  w-[7rem] py-[0.3rem] border-[1px] border-[#A7A7A7]'>Buy models</button>
 </div>
 </div>
 <div className='sm:flex hidden flex-col    flex-1'>
 <img src={io} alt="Logo" className='w-[21rem] min-w-[320px] brightness-[55%] pl-[98px] '/> {/* Inserisci l'immagine */}


 </div>
    </div>
    <img src={io} alt="Logo" className='w-[21rem] block sm:hidden pt-10 min-w-[320px] brightness-[55%] pl-[4rem] '/> {/* Inserisci l'immagine */}
     </div>
       {/* divisore */}
     <div className='flex pt-4 sm:pt-0  px-10 items-center '>
   <div className='flex flex-1 bg-[#6d6d6dc7] h-[1px] '></div>
  
   
     </div>
       {/* parte sotto */}
       <div className='flex justify-center    py-10 '>
        <div className=' items-center hidden md:flex '>
        <img src={blender} alt="Logo" className='w-[30rem] min-w-[480px] brightness-[90%] pl-[64px] '/> {/* Inserisci l'immagine */}
        </div>
       <div className='flex flex-col items-center md:items-start  pt-3   '>
  
  <p className=' text-[2rem]  leading-[1.4rem] font-semibold      pl-0    md:pl-[4rem]  font-inter m-0 p-0 text-[#E4D2D2]'>
   What can I do?
  </p>
 
  <p className='text-[2.2rem] leading-[3.7rem] font-semibold   font-inter     pl-0   md:pl-[4rem] text-[#ECC5A0]'>
   Everything you need
  </p>

  <p className='text-[0.6rem] sm:pr-0  pr-10 pl-10  font-inter  max-w-[30rem]  sm:text-left  text-center     md:pl-[4rem] text-[#A39C96]'>
  “ Hello. My name is Gianluigi and I'm a professional delevoper in many aspects, from making models, script, UIs on roblox to make 3D models to blender, and again from blender to website and app developer with react.js and firebase “   
  </p>
 
  <div className='flex  pl-0   md:pl-[4rem] pt-5 space-x-3'>
  <a href='mailto:regelly150@gmail.com?subject=%5BInserire%20oggetto%20della%20discussione%5D&body=%5BDimmi%20quello%20che%20vuoi%20sapere!%5D' className='bg-[#A47040] rounded-[6px] text-[0.7rem] text-center text-white w-[7rem] py-[0.4rem]' >Contact me</a>
  <button onClick={goToAboutPage} className='bg-transparent  rounded-[6px] text-[0.7rem] text-white  w-[7rem] py-[0.3rem] border-[1px] border-[#A7A7A7]'>Buy models</button>
  </div>
  </div>
       </div>
   
        {/* parte sotto 2 */}
       
        <div className='flex justify-center flex-col md:flex-row md:items-start  items-center   py-0  md:py-10 '>
        <img src={blender} alt="Logo" className='w-[23rem] md:hidden min-w-[320px] brightness-[90%] pb-10  '/> {/* Inserisci l'immagine */}
        <div className=' flex-col  ml-0  md:ml-[64px] items-center md:items-start  hidden md:flex   pt-5   '>
  
  <p className=' text-[2rem]  leading-[1.4rem] font-semibold    font-inter m-0 p-0 text-[#E4D2D2]'>
  Explore the stuffs
  </p>
 
  <p className='text-[2.2rem] leading-[3.7rem] font-semibold   font-inter     text-[#ECC5A0]'>
   That I made for you
  </p>
  <p className='text-[0.6rem] px-10   md:pr-0 md:pl-0   md:text-left  text-center font-inter  max-w-[30rem]    text-[#A39C96]'>
  “ Hello. My name is Gianluigi and I'm a professional delevoper in many aspects, from making models, script, UIs on roblox to make 3D models to blender, and again from blender to website and app developer with react.js and firebase “   
  </p>
 
  <div className=' flex pt-5 space-x-3'>
  <a href='mailto:regelly150@gmail.com?subject=%5BInserire%20oggetto%20della%20discussione%5D&body=%5BDimmi%20quello%20che%20vuoi%20sapere!%5D' className='bg-[#A47040] rounded-[6px] text-[0.7rem] text-center text-white w-[7rem] py-[0.4rem]' >Contact me</a>
  <button onClick={goToAboutPage} className='bg-transparent  rounded-[6px] text-[0.7rem] text-white  w-[7rem] py-[0.3rem] border-[1px] border-[#A7A7A7]'>Buy models</button>
  </div>
  </div>
        <img src={santa} alt="Logo" className='w-[26rem] md:block hidden   brightness-[90%] pr-0 md:pr-[20px]'/> {/* Inserisci l'immagine */}
       </div>
       <div className='flex justify-center mt-2 text-white text-[0.6rem] '>
    © 2024  da Gianluigi Izzo
    </div>
    </div>
  );
}

export default Home;


