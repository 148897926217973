
const options = {
    style: {
        
      base: {
        color: '#FFFFFF', // Colore personalizzato del testo
        fontSize: "13px",
        
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: 'antialiased',
        '::placeholder': {
          color: '#6e6e6e', // Colore del placeholder
        },
      },
      invalid: {
        color: '#fa755a', // Colore in caso di errore
      },
    },
  };
  
  export default options;