import React, {useEffect, useState} from 'react';
import {Firestore, collection, doc, getDocs, onSnapshot} from 'firebase/firestore'
import '../styles.css';
import { firestore } from '../FirebaseConfig'; 
import '../App.css';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { auth } from '../FirebaseConfig';
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import { storage } from "../FirebaseConfig";
import santa from '../images/Santa.png'; // Importa l'immagine
import Product from '../objects/Model';
import { onAuthStateChanged, signOut } from 'firebase/auth';

interface Model {
  id: string;
  nome: string;
  descrizione: string;
  immagine: string;
  prezzo: number;
  Categoria: string;
  StoragePath: string;
}

const Buy: React.FC = () => {

  const navigate = useNavigate();

  const [models, setModels] = useState<Model[]>([]);

  const goToAboutPage = () => {
    navigate('/TOS');
  };
 



  const [inputValue, setInputValue] = useState({
    Categoria: "Roblox Builds",
    Min: 0,
    Max: 300,
    Pagina: 0,
  });
  

  const handleinputchange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>)  => {
    const {name, value} = event.target;
  console.log(value)
    setInputValue({
      ...inputValue,
      [name]: value
    });
  }

  const logout =  async ()  => {

    signOut(auth);
    }


//getmodels
useEffect(() => {
  const ModelsCollection = collection(firestore, "Modelli");

  // Ascolta i cambiamenti in tempo reale nella collezione "Modelli"
  const unsubscribe = onSnapshot(ModelsCollection, async (snapshot) => {
    const models = await Promise.all(snapshot.docs.map(async (Doc) => {

      const imageRef = ref(storage, `Modelli/${Doc.id}/${Doc.id}.jpg`);
      const imageUrl = await getDownloadURL(imageRef);
      return {
      id: Doc.id,
      nome: Doc.data()?.Nome || "Nome non disponibile",
      descrizione: Doc.data()?.Descrizione || "Descrizione non disponibile",
      immagine: imageUrl,
      prezzo: Doc.data()?.Prezzo || "Descrizione non disponibile",
      Categoria: Doc.data()?.Categoria || "Cat non disponibile",
      StoragePath: Doc.data()?.Storagepath || "PathNonDisponibile",
   };
   }));

    setModels(models); // Aggiorna lo stato con i modelli aggiornati
  }, (error) => {
    console.error("Errore nell'ascoltare i modelli:", error);
  });

  // Cleanup: chiude la connessione quando il componente viene smontato
  return () => unsubscribe();
}, []); // Si aggiorna solo una volta all'inizio


  





  return (
    <div className='flex flex-col h-screen  overflow-auto bg-home2  scrollbar-custom '  >
    <header className="flex     text-[1.3rem]  text-[#ECC5A0] font-imperial p-3 ">
      <div className='w-[30%] hidden sm:flex'>
      Gianluigi Izzo  
      </div>
      <div className='flex flex-1 items-center justify-center   text-[#8b8b8b] text-[0.7rem] space-x-3  '>
      <Link className='flex   justify-center     font-inter ' to="/">
       
       Home
          
     
            
           
           </Link>
       
           <Link className='flex  text-[#ffffff]   justify-center     font-inter ' to="/Buy">
            
            Buy Models
               
          
                 
                
                </Link>
                <Link className='flex   justify-center     font-inter ' to="/Cronology">
            
            Cronology boughts
               
          
                 
                
                </Link>
                <Link className='flex   justify-center     font-inter ' to="/TOS">
            
            Terms of Service
               
          
                 
                
                </Link>
           </div>
           <div className='flex w-[30%] font-inter text-[0.8rem] items-center justify-end'>
    
    {auth.currentUser?.displayName}
    <div className='flex  font-inter '>
  <button onClick={logout} className='bg-[#A47040] ml-2 text-[0.7rem] py-1 text-white px-1 rounded-[5px] '>
    Log out
    </button>
</div>
</div>
    </header>
  
    
     
     
 
   
        {/* Parte iniziale */}
       
        <div className='flex justify-center flex-col md:flex-row md:items-start  items-center   py-0  md:py-2 '>
      
        <div className=' flex-col  ml-0  md:ml-[64px] items-center md:items-start  flex   pt-5   '>
  
  <p className=' text-[2rem]  leading-[2.3rem] font-semibold     font-inter m-0 p-0 text-[#E4D2D2]'>
Enjoy The Most
  </p>
 
  <p className='text-[2.2rem] leading-[2.3rem] font-semibold  py-2   font-inter     text-[#ECC5A0]'>
   Beautiful Models
  </p>
  <p className='text-[0.6rem] px-10   md:pr-0 md:pl-0   md:text-left  text-center font-inter  max-w-[30rem]    text-[#A39C96]'>
  “ Hello. My name is Gianluigi and I'm a professional delevoper in many aspects, from making models, script, UIs on roblox to make 3D models to blender, and again from blender to website and app developer with react.js and firebase “   
  </p>
 
  <div className='flex  pt-5 space-x-3'>
  <a href='mailto:regelly150@gmail.com?subject=%5BInserire%20oggetto%20della%20discussione%5D&body=%5BDimmi%20quello%20che%20vuoi%20sapere!%5D' className='bg-[#A47040] rounded-[6px] text-[0.7rem] text-center text-white w-[7rem] py-[0.4rem]' >Contact me</a>
  <button onClick={goToAboutPage} className='bg-transparent  rounded-[6px] text-[0.7rem] text-white  w-[7rem] py-[0.3rem] border-[1px] border-[#A7A7A7]'>Terms of Service</button>
  </div>
  </div>

       
        <img src={santa} alt="Logo" className='w-[26rem] md:block hidden   brightness-[90%] pr-0 md:pr-[20px]'/> {/* Inserisci l'immagine */}
      
    
       </div>
     <div className='flex flex-col px-[1rem] sm:px-[4.5rem] pt-5 '>
<div className='flex  flex-row'>
 
<div className='flex flex-1 space-x-3 mt-2'>
<div className='flex flex-col justify-end'>
      <h3 className='text-white text-sm'>Category</h3>
      <select  className='border-2 p-1 text-white text-[0.7rem] border-[#6161617a] rounded-[5px] bg-transparent'  value={inputValue.Categoria} name={"Categoria"} onChange={handleinputchange}>
       
        <option className='bg-transparent text-black' value="Roblox Builds">Roblox builds</option>
        <option className='bg-transparent text-black' value="Roblox Scripts">Roblox scripts</option>
        <option className='bg-transparent text-black' value="Roblox Maps">Roblox maps</option>
        <option className='bg-transparent text-black' value="Roblox Meshes">roblox meshes</option>
        <option className='bg-transparent text-black' value="Roblox Cars">roblox cars</option>
        
      </select>
      </div>
      <div >
      <h3 className='text-white text-sm'>Min price</h3>
        <input
        type="text"
        className='w-[4rem] sm:w-[6rem] bg-transparent border-2 border-[#6161617a] text-white border-collapse rounded-[5px] p-1 text-[0.7rem] '
        value={inputValue.Min} // Lega l'input allo stato
          name="Min"
        onChange={handleinputchange} // Gestore per il cambiamento dell'input
        placeholder='Min Price'
      />
       </div>
       <div>
       <h3 className='text-white text-sm'>Max price</h3>
        <input
        type="text"
        className='w-[4rem] sm:w-[6rem] bg-transparent border-2 border-[#6161617a] text-white border-collapse rounded-[5px] p-1 text-[0.7rem] '
        value={inputValue.Max} // Lega l'input allo stato
          name="Max"
        onChange={handleinputchange} // Gestore per il cambiamento dell'input
        placeholder='Max price'
      />
      </div>
      </div>
      <div className='flex flex-col justify-end'>
      <h3 className='text-white text-sm'>Page</h3>
      <select  className='border-2 p-1 w-[4rem] sm:w-[6rem] text-white text-[0.7rem] border-[#6161617a] rounded-[5px] bg-transparent'  value={inputValue.Pagina} name={"Pagina"} onChange={handleinputchange}>
       
        <option className='bg-transparent text-black' value="0">In evidence</option>
        <option className='bg-transparent text-black' value="1">1</option>
        <option className='bg-transparent  text-black' value="2">2</option>
      </select>
      </div>
</div>
<div className='grid justify-items-center  grid-custom-cols-2 grid-custom-cols-3 grid-custom-cols-4 grid-custom-cols-5 grid-custom-cols-6 gap-x-[2px]  '>
{models.slice(inputValue.Pagina * 10, inputValue.Pagina * 10 + 10).map((model) => (
  // Verifica se il prezzo del modello è maggiore di 30
  model.prezzo <= inputValue.Max && model.prezzo >= inputValue.Min && model.Categoria === inputValue.Categoria ? (
    <Product
      key={model.id} // Usare una chiave univoca per ogni elemento in lista
      name={model.nome}
      description={model.descrizione}
      image={model.immagine}
      prezzo={model.prezzo}
      id={model.id}
      path={model.StoragePath}
    />
  ) : null
))}


</div>
     </div>
     
     <div className='flex justify-center mt-2 text-white text-[0.6rem] '>
    © 2024  da Gianluigi Izzo
    </div>
    </div>
  );
}

export default Buy;


