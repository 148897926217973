// src/components/Product.tsx
import React from 'react';
import blender from '../images/blender.jpg';

import { useNavigate } from 'react-router-dom';

// Definiamo il tipo delle props per il componente Product
interface ProductProps {
  name: string
  description: string;
  image: string;
  prezzo: number;
  id: string;
  path: string;
}

const Product: React.FC<ProductProps> = ({name,  description, image, prezzo, id, path}) => {
  
  const navigate = useNavigate();
  const goToAboutPage = ( articleId: string) => {
    navigate(`/articles/${articleId}`);
  };
  
  return (
    <div className="flex flex-col w-[10rem] bg-[#141210] border-[2px] border-[#2e2c29] py-2 mt-3  rounded-[5px] px-2">
        <div className='flex h-[6rem]'>
<div className=' w-[100%]  rounded-[5px]  bg-slate-200'>
<img src={image} alt="Logo" className=' w-full h-full object-cover object-center rounded-[5px] " brightness-[90%] '/> {/* Inserisci l'immagine */}
</div>
</div>

<div className='text-white'>
    {name}
</div>
    
<div className='text-[#9c9a95] text-[0.5rem] pb-2'>
{description.length > 63 ? description.substring(0,63) + "..." : description}
</div>

<div className='flex '>
    <div className='flex flex-1 text-[0.55rem] text-[#6fb446]'>
    {prezzo + " €"}
    </div>
    <button onClick={() => goToAboutPage(id)} className='border-[1px] text-white border-[#947e5c] py-0 text-[0.3rem] px-2 rounded-[2px]'>More</button>
</div>
    </div>
  );
};

export default Product;
