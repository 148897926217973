import React, {useEffect, useState} from 'react';
import '../styles.css';
import logo from './logo.svg';
import '../App.css';
import { useNavigate } from 'react-router-dom';
import { auth } from '../FirebaseConfig';
import { isMobile } from 'react-device-detect';
import google from "../images/google.png"
import { onAuthStateChanged, signInWithEmailAndPassword, GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
function Login() {
  const navigate = useNavigate();
  const [logged, setlogged] = useState(false);
  const [erroremess, seterroremess] = useState("")
  
  const goToAboutPage = () => {
    navigate('/register');
  };

 
  
  console.log(isMobile)
  useEffect(() => {
    // Verifica che Firebase Authentication sia attivo e monitori lo stato di autenticazione
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        console.log('Utente loggato:', user);
      } else {
        console.log('Nessun utente loggato');
      }
    });

    return () => unsubscribe(); // Cleanup per evitare memory leak
  }, []);


  const [inputValue, setInputValue] = useState({
    email: "",
    password: "",
  });
  
  const handleLogin = async (e: React.FormEvent) => {
    e.preventDefault();

    try {
      // Autentica l'utente con email e password
      await signInWithEmailAndPassword(auth, inputValue.email, inputValue.password);
      // Reindirizza alla home una volta loggato
      navigate('/');
    } catch (error: any) {
      // Imposta un messaggio d'errore in caso di login fallito
      if (error.code == "auth/invalid-email") {
        seterroremess("L'email inserita non è valida")
      }
      else if(error.code == "auth/invalid-credential" || error.code == "auth/missing-password" ) {
        seterroremess("La password inserita non è corretta")
      }
      console.error("Errore di autenticazione:", error.message);
    }
  };

  const handleinputchange = (event: React.ChangeEvent<HTMLInputElement>)  => {
    const {name, value} = event.target;
  console.log(value)
    setInputValue({
      ...inputValue,
      [name]: value
    });
  }

  const signInWithGoogle = async () => {
    const provider = new GoogleAuthProvider();

    try {
      const result = await signInWithPopup(auth, provider);
      // Ottieni le informazioni dell'utente
      const user = result.user;
      console.log("Utente loggato:", user);
    } catch (error) {
      console.error("Errore durante il login con Google:", error);
    }
  };




  if (logged == true) {
    return (
      <div>request failed: already logged in</div>
    )
   
    }

  return (
    <div className='flex flex-col h-screen overflow-auto bg-angular-gradient scrollbar-custom  ' >
    <header className="text-[1.3rem] z text-[#ECC5A0] font-imperial p-2">
      Gianluigi Izzo
    </header>
    {/* FRAME_1 */} 
    <div className='flex flex-1 justify-center items-center pb-10'>
     <div className='flex flex-1 sm:flex-row flex-col  justify-center' >
   

    <div className=' flex-col items-start justify-start pt-6 p-3 hidden md:flex    '>
  <div>
  <p className=' text-[2.5rem]  leading-[2.4rem] font-semibold  pl-0   sm:pl-[4rem]  font-inter m-0 p-0 text-[#E4D2D2]'>
   Welcome to
  </p>
 
  <p className='text-[3rem] leading-[3.7rem] font-semibold   font-inter    pl-0   sm:pl-[4rem] text-[#ECC5A0]'>
  Giangibuilds
  </p>
  <p className='text-[0.6rem] sm:pr-0  pr-10 pl-10  py-2  font-inter  max-w-[25rem] sm:text-left  text-center    sm:pl-[4rem] text-[#A39C96]'>
  GiangiBuilds was born from a passion for craftsmanship and design, offering meticulously crafted models to inspire creativity and elevate the art of collecting and building worldwide.
  </p>
 
  <div className='flex   sm:pl-[4rem] pt-5 space-x-3 '>
   <a href='mailto:regelly150@gmail.com?subject=%5BInserire%20oggetto%20della%20discussione%5D&body=%5BDimmi%20quello%20che%20vuoi%20sapere!%5D' className='bg-[#A47040] rounded-[6px] text-[0.7rem] text-center text-white w-[7rem] py-[0.4rem]' >Contact me</a>
   <button onClick={goToAboutPage} className='bg-transparent  rounded-[6px] text-[0.7rem] text-white  w-[7rem] py-[0.3rem] border-[1px] border-[#A7A7A7]'>Terms of Service</button>
  </div>
  </div>
  </div>





<div className='flex  flex-col   justify-start items-center pr-0 sm:pr-16 '>
  {/* RIQUADRO_1 */}     <div className={`w-full flex-col p-2 px-6 font-inter font-light ${isMobile ? 'sm:max-w-[18rem] sm:border-2 sm:border-collapse sm:border-[#6161617a]' : 'max-w-[18rem] border-2 border-collapse border-[#6161617a]'} rounded-[5px]  `}>
        {/* LOGIN */}
       <div className={` flex justify-center text-white ${isMobile ? 'text-[3.7rem] sm:text-[2.3rem]' : 'text-[2.3rem]' } `}>
        Login
       </div>
       <div className={` flex flex-col justify-center  text-white ${isMobile ? 'text-[1.2rem] sm:text-[0.8rem]' : 'text-[0.8rem]' }    font-inter font-light `}>
       
        {/* EMAIL INPUT */}
       
        <div className=' flex justify-start pt-4  '>
          Email
        </div>
        <input
        type="text"
        className={`bg-transparent border-2 border-[#6161617a] text-white border-collapse rounded-[5px] p-1 ${isMobile ? 'text-[1rem] sm:text-[0.7rem]' : 'text-[0.7rem]' } `}
        value={inputValue.email} // Lega l'input allo stato
          name="email"
        onChange={handleinputchange} // Gestore per il cambiamento dell'input
        placeholder='Ex: REgelly150@gmail.com'
      />
     
     {/* PASSWORD INPUT */}
     <div className=' flex justify-start pt-2  '>
          Password
        </div>
        <input
        type="Password"
        className={`bg-transparent border-2 border-[#6161617a] text-white border-collapse rounded-[5px] p-1 ${isMobile ? 'text-[1rem] sm:text-[0.7rem] ' : 'text-[0.7rem]' } `}
        value={inputValue.password} // Lega l'input allo stato
        name="password"
        onChange={handleinputchange} // Gestore per il cambiamento dell'input
        placeholder='Ex: improplayer12!'
        
      />

       {/* Accedi */}
     <div className='flex flex-col justify-center items-center mt-5 mb-5 p-2'>
     <button onClick={handleLogin} className={`flex border-[1px] border-[#ffffffb0]  rounded-[10px] ${isMobile ? 'w-[100%] sm:max-w-[13rem]' : 'w-[13rem]' }  p-1 justify-center   `}>
      Accedi
      </button>

      <button onClick={signInWithGoogle} className={`flex items-center border-[1px] mt-2 border-[#bebebeb0]  rounded-[10px] ${isMobile ? 'w-[100%]' : 'w-[13rem]' }  p-1 justify-center   `} > <img src={google} className='w-4 mr-1' ></img>Accedi con Google</button>
    
      { erroremess? 
      <div className={` flex flex-col pt-2 justify-start  ${isMobile ? 'text-[0.85rem] sm:text-[0.5rem]' : 'text-[0.5rem]' } text-[#ca2d2d] text-center `}>{erroremess}</div>
      :
<div></div>
      }
     </div>
       {/* Dividi */}
       <div className='flex space-x-3 items-center '>
   <div className='flex flex-1 bg-[#999999c7] h-[1px] '></div>
   <div className='flex  rounded-full  border-[1px] border-[#999999c7] h-[1rem] w-[1rem] '></div>
   <div className='flex flex-1 bg-[#999999c7] h-[1px] '></div>
   
     </div>

       {/* Possiedi già un account? */}
       <div className={`flex  justify-center  p-1 mt-4 ${isMobile ? 'text-[1rem] sm:text-[0.7rem]' : 'text-[0.7rem]' }`}>
     Non hai ancora un account?
     </div>
        {/* Registrati */}
        <div className='flex  justify-center  p-2'>
     <button onClick={goToAboutPage} className={`flex border-[1px] border-[#dfa53bb0]  rounded-[10px] ${isMobile ? 'w-[100%]' : 'w-[13rem]' }  p-1 justify-center   `}>
      Registrati
      </button>
      
     </div>
       </div>
     </div>
      

    </div>
    {/* FRAME_2 */}
    
    </div>
    </div>
    <div className='flex justify-center mt-2 text-white text-[0.6rem] '>
    © 2024  da Gianluigi Izzo
    </div>
    
    </div>
  );
}

export default Login;
