import React, {useEffect, useState} from 'react';
import {Firestore, collection, doc, getDocs, onSnapshot} from 'firebase/firestore'
import '../styles.css';
import { firestore } from '../FirebaseConfig'; 
import '../App.css';
import { auth } from '../FirebaseConfig';
import { onAuthStateChanged, signOut } from 'firebase/auth';
import blender from '../images/blender.jpg'; // Importa l'immagine
import { useNavigate } from 'react-router-dom';
import options from './altristili';
import { Link } from 'react-router-dom';

const logout =  async ()  => {

  signOut(auth);
  }
  

const Tos: React.FC = () => {



  const navigate = useNavigate();




  return (
    <div className='flex flex-col h-screen  overflow-auto bg-home2  scrollbar-custom  px-2 '  >
    <header className="flex     text-[1.3rem]  text-[#ECC5A0] font-imperial p-1">
      <div className='w-[30%] hidden sm:flex'>
      Gianluigi Izzo  
      </div>
      <div className='flex flex-1 items-center justify-center   text-[#8b8b8b] text-[0.7rem] space-x-3  '>
      <Link className='flex   justify-center     font-inter ' to="/">
       
       Home
          
     
            
           
           </Link>
       
           <Link className='flex     justify-center     font-inter ' to="/Buy">
            
            Buy Models
               
          
                 
                
                </Link>
                <Link className='flex    justify-center     font-inter ' to="/Cronology">
            
            Cronology boughts
               
          
                 
                
                </Link>
                <Link className='flex text-[#ffffff]  justify-center     font-inter ' to="/TOS">
            
            Terms of Service
               
          
                 
                
                </Link>
           </div>
           <div className='flex w-[30%] font-inter text-[0.8rem] items-center justify-end'>
    
    {auth.currentUser?.displayName}
    <div className='flex  font-inter '>
  <button onClick={logout} className='bg-[#A47040] ml-2 text-[0.7rem] py-1 text-white px-1 rounded-[5px] '>
    Log out
    </button>
</div>
</div>
    </header>
  
<div className='flex font-semibold text-[#ffffff] text-[3rem] font-inter justify-center'>
  Terms of Service  
</div>



<div className='flex flex-row space-x-2 justify-center p-3 pt-10'>


<div className='flex flex-col max-w-[20rem]'>
<div className='flex font-semibold text-[#ffffff] text-[1rem] font-inter justify-center'>
Accpetance of Terms
</div>
<div className='flex  text-[#999999] text-[0.7rem] font-inter text-center'>
By using our Site, you represent that you are at least 18 years old or minor but you are using the Site under the supervision of a parent or legal guardian. You agree to abide by these Terms and all applicable laws and regulations.
</div>
</div>


<div className='flex flex-col  max-w-[20rem]'>
<div className='flex font-semibold text-[#ffffff] text-[1rem] font-inter justify-center'>
Changes of Terms 
</div>
<div className='flex  text-[#999999] text-[0.7rem] font-inter text-center'>
We reserve the right to modify these Terms at any time. Any changes will be posted on this page with a new effective date. We encourage you to review this page regularly for updates.
</div>
</div>


</div>

<div className='flex flex-row space-x-2 justify-center p-3 pt-4'>


<div className='flex flex-col max-w-[20rem]'>
<div className='flex font-semibold text-[#ffffff] text-[1rem] font-inter justify-center'>
Products and Pricing
</div>
<div className='flex  text-[#999999] text-[0.7rem] font-inter text-center'>
The products sold on our Site consist solely of digital files, including but not limited to images, documents, and other downloadable content.
 We reserve the right to change product prices at any time without prior notice. The final price will be displayed at the time of purchase. Not all Products are made by Gianluigi 
</div>
</div>


<div className='flex flex-col  max-w-[20rem]'>
<div className='flex font-semibold text-[#ffffff] text-[1rem] font-inter justify-center'>
Refund Policy 
</div>
<div className='flex  text-[#999999] text-[0.7rem] font-inter text-center'>
Due to the nature of digital products, all sales are final. We do not offer refunds or exchanges once the digital files have been downloaded.
 If you encounter a technical issue that prevents you from accessing your purchased files, please contact us within 7 days of your purchase, and we will do our best to resolve the issue.
</div>
</div>


</div>

<div className='flex flex-row space-x-2 justify-center p-3 pt-4'>


<div className='flex flex-col max-w-[20rem]'>
<div className='flex font-semibold text-[#ffffff] text-[1rem] font-inter justify-center'>
Delivery
</div>
<div className='flex  text-[#999999] text-[0.7rem] font-inter text-center'>
Upon successful payment, digital files will be made available for immediate download. 
 If you experience any issues accessing your files, please contact us at regelly150@gmail.com
</div>
</div>


<div className='flex flex-col  max-w-[20rem]'>
<div className='flex font-semibold text-[#ffffff] text-[1rem] font-inter justify-center'>
Payment
</div>
<div className='flex  text-[#999999] text-[0.7rem] font-inter text-center'>
We accept payments via credit card and PayPal. All payments are securely processed through our payment service provider. We do not store your credit card information.
</div>
</div>


</div>

<div className='flex flex-row space-x-2 justify-center p-3 pt-4'>
<div className='flex flex-col max-w-[20rem]'>
<div className='flex font-semibold text-[#ffffff] text-[1rem] font-inter justify-center'>
Intellectual Property
</div>
<div className='flex  text-[#999999] text-[0.7rem] font-inter  text-center'>
All content, trademarks, images, and materials on our Site are owned by us or used with permission from the rightful owners. You may not use, copy, or distribute any content without our express consent.
</div>
</div>


<div className='flex flex-col  max-w-[20rem]'>
<div className='flex font-semibold text-[#ffffff] text-[1rem] font-inter justify-center'>
Limitation of Liability
</div>
<div className='flex  text-[#999999] text-[0.7rem] font-inter  text-center'>
We shall not be liable for any indirect, incidental, or consequential damages arising from the use or inability to use our Site or the digital products sold.
</div>
</div>


</div>

<div className='flex flex-row space-x-2 justify-center p-3 pt-4'>
<div className='flex flex-col max-w-[20rem]'>
<div className='flex font-semibold text-[#ffffff] text-[1rem] font-inter justify-center'>
Governing Law
</div>
<div className='flex  text-[#999999] text-[0.7rem] font-inter text-center'>
These Terms shall be governed by and construed in accordance with the laws of Italy without regard to its conflict of law principles. Any disputes arising from these Terms shall be resolved in the competent courts of Italy.
</div>
</div>


<div className='flex flex-col  max-w-[20rem]'>
<div className='flex font-semibold text-[#ffffff] text-[1rem] font-inter justify-center'>
Contact Information
</div>
<div className='flex  text-[#999999] text-[0.7rem] font-inter text-center'>
For questions regarding these Terms, please contact us at: regelly150@gmail.com
</div>
</div>


</div>

<div className='flex justify-center mt-2 text-white text-[0.6rem] '>
    © 2024  da Gianluigi Izzo
    </div>
  </div>
   
  );
}
export default Tos;


