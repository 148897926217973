// src/components/Product.tsx
import React from 'react';
import blender from '../images/blender.jpg';

import { useNavigate } from 'react-router-dom';

// Definiamo il tipo delle props per il componente Product
interface ProductProps {
 immagine: string
}

const Immagine: React.FC<ProductProps> = ({ immagine }) => {
  

  
  return (
    <img
    src={immagine} // sostituisci con il tuo URL dell'immagine
    alt="Notloaded"
    className="w-[15rem] pb-1 "
  />
  );
};

export default Immagine;
